import React from "react";
import styles from "./FooterImage.module.css";
const FooterImage = ({ image, link, description }) => {
  return (
    <li>
      <a
        href={link}
        target='_blank'
        rel='noreferrer'
      >
        <img
          className={styles.footer_image}
          src={image}
          alt={description}
        />
      </a>
    </li>
  );
};

export default FooterImage;
