import React from "react";
import styles from "./GetInTouch.module.css";
const GetInTouch = () => {
  function handleClick() {
    window.alert("I'll get back to you shortly !");
  }

  return (
    <div
      className={styles.GetInTouch_container}
      id='contact'
    >
      <div className={styles.form_container}>
        <form action=''>
          <span className={styles.span_center}>
            <h2>Get In Touch</h2>
          </span>
          <p>Feel free to send a message for a job, a duo partner, or if you want to watch anime together!</p>
          <div className={styles.form_element}>
            <label htmlFor='Name'>Name</label>
            <input
              type='text'
              name='Name'
              placeholder='Paul Lim'
            />
          </div>
          <div className={styles.form_element}>
            <label htmlFor='Email'>Email</label>
            <input
              type='email'
              name='Email'
              placeholder='Paulslim47@gmail.com'
            />
          </div>
          <div className={styles.form_element}>
            <label htmlFor='Message'>Message</label>
            <textarea
              id='Message'
              name='Message'
              placeholder="I'm Gold 4 and looking for a support"
            />
          </div>
          <button
            type='button'
            className={styles.button}
            onClick={handleClick}
          >
            Send Message!
          </button>
        </form>
      </div>
    </div>
  );
};

export default GetInTouch;
