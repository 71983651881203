import React from "react";
import styles from "./Footer.module.css";
import FooterImage from "./FooterImage";

const images = [
  { description: "facebook", link: "https://www.facebook.com/paul.lim.1800/", image: "facebook.png" },
  { description: "github", link: "https://github.com/seunghan47", image: "github.png" },
  { description: "instagram", link: "https://www.instagram.com/paur_rim/", image: "instagram.png" },
  { description: "linkedin", link: "https://www.linkedin.com/in/paul-lim47/", image: "linkedin.png" },
];

const Footer = () => {
  return (
    <div className={styles.footer_container}>
      <div className={styles.left_footer}> Copyright © Paul Lim 2024</div>
      <div className={styles.right_footer}>
        {images.map((image) => (
          <FooterImage
            image={image.image}
            link={image.link}
            description={image.description}
          />
        ))}
      </div>
    </div>
  );
};

export default Footer;
