import React from "react";
import styles from "./Accomplish.module.css";
import Grid from "./Grid";

const goals = [
  {
    id: 1,
    header: "Machine learning / Data science",
    paragraph:
      "Master the fundamentals of machine learning and data science, applying them to real-world problems. Develop expertise in data analysis, predictive modeling, and implement solutions using cutting-edge ML frameworks.",
  },
  {
    id: 2,
    header: "Software Architecture",
    paragraph:
      "Deepen understanding of software architecture principles, focusing on designing scalable and maintainable systems. Enhance proficiency in creating robust, modular architectures that align with industry best practices.",
  },
  {
    id: 3,
    header: "Master works in the cloud",
    paragraph:
      "Achieve advanced mastery in cloud computing, specifically focusing on optimizing cloud infrastructure, enhancing security measures, and exploring advanced services. Attain certifications to validate expertise in cloud technologies, especially within the AWS ecosystem",
  },
  {
    id: 4,
    header: "Learn a new Programming language ",
    paragraph:
      "Acquire proficiency in a new programming language to broaden skill set. Apply the language in practical scenarios, gaining hands-on experience and versatility for diverse software development projects",
  },
];

const Accomplish = () => {
  return (
    <div className={styles.accomplish}>
      <span className={styles.span}>Goals for 2024</span>
      <hr />
      <div className={styles.grid_container}>
        {goals.map((goal) => (
          <Grid
            key={goal.id}
            header={goal.header}
            paragraph={goal.paragraph}
          />
        ))}
      </div>
    </div>
  );
};

export default Accomplish;
