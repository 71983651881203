import React from "react";
import styles from "./Grid.module.css";

const Grid = ({ header, paragraph }) => {
  return (
    <div className={styles.grid_item}>
      <h3>{header}</h3>
      <p>{paragraph}</p>
    </div>
  );
};

export default Grid;
