import React from "react";
import styles from "./Works.module.css";
import Work from "./Work";

const Works = () => {
  return (
    <div className={styles.works}>
      <h2>Some of my work</h2>
      {works.map((work) => (
        <Work
          id={work.id}
          title={work.title}
          image={work.image}
          description={work.description}
        />
      ))}
    </div>
  );
};

export default Works;

const works = [
  {
    id: 1,
    title: "L beauty",
    image: "Lbeauty.png",
    description:
      "This is a first real world full stack website that I built. It is an e-commerce website that I made it for a local business in my community and the website was made from scratch using react, spring, mongoDb and it was deployed on AWS with s3 bucket and cloudFront",
  },
  {
    id: 2,
    title: "Nhu's Nail Salon",
    image: "Nhu.png",
    description:
      "Nhu's nail is a website I built for a client who does her personal nail shops. Due to rising customer demands, it was difficult to continuously go back and forth with her customer base trying to set up a proper time for the service. So I made her a website where they can register and book an appointment with real time data",
  },
  {
    id: 3,
    title: "V1 Personal Website",
    image: "v1.png",
    description: "v1 of my multi page personal website built with react/react-router-dom",
  },
  {
    id: 4,
    title: "Vivek, Elite athletes",
    image: "vivek.png",
    description:
      "Website where people can request for Vivek, a top D1 athlete, to join their team for any sports of their desire",
  },
];
