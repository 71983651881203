import React from "react";
import styles from "./About.module.css";

const About = () => {
  return (
    <div
      className={styles.about_container}
      id='about'
    >
      <div className={styles.left_item}>
        <h1>Hey, I'm Paul.</h1>
        <p>
          <h4>
            I'm a pragmatic and detail-oriented software engineer, passionate about creating robust code that gets the
            job done efficiently.
          </h4>
          <hr />
          <span className={styles.highlight}>I graduated from the University of Georgia </span>in 2023 and am on a
          journey to become a well-rounded software engineer. My interest spans across various domains, and I'm always
          eager to dive into new technologies and frameworks. Whether it's tackling complex problems or optimizing code
          for performance, I find satisfaction in building solutions that work seamlessly.
          <br />
          <br />
          <span className={styles.highlight}>I view my career as an ongoing learning experience. </span>
          I'm keen on exploring different facets of software engineering and am open to collaborations with individuals
          who share insights and knowledge.
          <br />
          <br />
          My goal is to contribute effectively to projects and continuously expand my skill set.
        </p>
      </div>
      <div className={styles.right_item}>
        <img
          src='paul.png'
          alt=''
        />
      </div>
    </div>
  );
};

export default About;
