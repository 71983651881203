import React from "react";
import styles from "./Header.module.css";
import { useState } from "react";
import { Link } from "react-scroll";

const Header = () => {
  const [scrolled, setScrolled] = useState(false);

  function handleScrolled() {
    const scrollTop = window.scrollY;
    setScrolled(scrollTop > 100);
  }

  window.onscroll = handleScrolled;

  return (
    <div className={`${styles.header_flex_container} ${styles.fixed_header} ${scrolled ? styles.scrolled : ""}`}>
      <div className={styles.left}>
        <Link
          to='home'
          smooth={true}
        >
          Paul Lim
        </Link>
      </div>

      <div className={styles.right}>
        <ul className={styles.item_container}>
          <li className={styles.items}>
            <Link
              to='about'
              smooth={true}
            >
              About
            </Link>
          </li>
          <li className={styles.items}>
            <Link
              to='skills'
              smooth={true}
            >
              Skills
            </Link>
          </li>
          <li className={styles.items}>
            <Link
              to='projects'
              smooth={true}
              offset={-280}
            >
              Projects
            </Link>
          </li>
          <li className={styles.items}>
            <Link
              to='contact'
              smooth={true}
            >
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
