import React from "react";
import styles from "./Home.module.css";
const Home = () => {
  return (
    <div
      className={styles.image_container}
      id='home'
    >
      <img
        className={styles.wallpaper}
        src='comet.jpeg'
        alt=''
      />
      <div className={styles.text}>
        <h1>Paul Lim</h1>
      </div>
      <div className={styles.textP}>
        <p>2023 UGA Grad - Freelancer - Software Engineer - Web Developer</p>
      </div>
      <img
        className={styles.paul}
        src='paul.png'
        alt=''
      />
    </div>
  );
};

export default Home;
