import React from "react";
import styles from "./Skills.module.css";
import Grid from "./Grid";

const skills = [
  {
    id: 1,
    header: "Front-end",
    paragraph:
      "With expertise in HTML, CSS, and JavaScript, I specialize in React for dynamic user interfaces. I turn design concepts into pixel-perfect layouts and prioritize creating visually appealing and intuitive websites. Staying updated on industry trends is crucial to my frontend development approach.",
  },
  {
    id: 2,
    header: "Back-end",
    paragraph:
      "I'm skilled in Java and Spring for robust backend development. I focus on creating scalable APIs, ensuring data security, and building efficient server-side applications. My goal is to craft enterprise-level solutions that integrate seamlessly.",
  },
  {
    id: 3,
    header: "Cloud Infrastructure",
    paragraph:
      "I'm a cloud enthusiast with mastery in AWS, holding certifications in Cloud Practitioner and Developer Associate. My experience includes cloud-native app development, serverless computing, and infrastructure orchestration. I focus on delivering scalable, secure, and cost-effective solutions aligned with industry best practices.",
  },
  {
    id: 4,
    header: "Positive attitude",
    paragraph:
      "Driven by curiosity, I bring a positive attitude to continuous learning. Each project is an opportunity for growth, and I actively seek collaboration to innovate. Committed to fostering a culture of improvement, I approach challenges with an open mind, aiming not just for exceptional solutions but also collective achievement.",
  },
];

const Skills = () => {
  return (
    <div
      className={styles.skills}
      id='skills'
    >
      <span className={styles.span}>Skills</span>
      <hr />
      <div className={styles.grid_container}>
        {skills.map((skill) => (
          <Grid
            key={skill.id}
            header={skill.header}
            paragraph={skill.paragraph}
          />
        ))}
      </div>
    </div>
  );
};

export default Skills;
