import React from "react";
import styles from "./Work.module.css";

const Work = ({ children, title, description, image }) => {
  return (
    <div
      className={styles.work}
      id='projects'
    >
      <img
        className={styles.image}
        src={image}
        alt={description}
      />
      <h1>{title}</h1>
      <p>{description}</p>
    </div>
  );
};

export default Work;
